<template>
  <div v-if="showAd" :style="$props.stylesSm && googleAdsStyleSm">
    <script2 type="text/javascript" async="true" :src="`${url}`" crossorigin="anonymous"></script2>
    <ins class="adsbygoogle"
         :style="$props.styles"
         data-ad-client="ca-pub-3935332587958057"
         :data-ad-slot="$props.adSlot"></ins>
    <script2 type="text/javascript">
      (adsbygoogle = window.adsbygoogle || []).requestNonPersonalizedAds = 1; (adsbygoogle = window.adsbygoogle || []).push({});
    </script2>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import resizeMixin from '../mixins/resize.mixin'

export default {
  name: 'GoogleAdSense',
  mixins: [resizeMixin],
  props: {
    adSlot: {
      type: String,
      required: true
    },
    styles: {
      type: String,
      default: 'display:inline-block;min-width:300px;max-width:970px;width:100%;min-height:300px;max-height:970px;height:100%;'
    },
    stylesSm: {
      type: Boolean,
      default: false
    },
    sequence: {
      type: [Number, String],
      default: 1
    }
  },
  data: () => {
    return {
      showAd: false,
      timoutIdx: null,
      url: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3935332587958057',
      googleAdsStyleSm: 'display:inline-block;min-width:150px;max-width:300px;width:100%;min-height:150px;max-height:300px;height:100%;'
    }
  },
  mounted() {
    // const url = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3935332587958057'
    // this.loadScript(url, this.loadAds)
    this.$nextTick(() => {
      this.timoutIdx = setTimeout(() => {
        this.showAd = this.isFreeUser
      }, +this.$props.sequence * 1000)
    })
  },
  beforeDestroy() {
    // this.cleanupAds(this.$props.adSlot)
    if (this.timoutIdx) {
      clearTimeout(this.timoutIdx)
    }
  },
  computed: {
    ...mapGetters(['userProfile']),
    isFreeUser() {
      return this.userProfile?.is_free_user && !window.location.origin.includes('test') && !window.location.origin.includes('localhost') && (this.window.width ? this.window.width > 120 : true)
    }
  },
  methods: {
    // loadScript(url, callback) {
    //   const script = document.createElement('script')
    //   script.type = 'text/javascript'
    //   script.src = url
    //   script.crossorigin = 'anonymous'
    //   script.async = true
    //
    //   script.onload = function() {
    //     if (callback) {
    //       callback()
    //     }
    //   }
    //   document.head.appendChild(script)
    // },
    // loadAds() {
    //   if (!this.isFreeUser || !window.adsbygoogle) return
    //   /* eslint-disable-next-line */
    //   (window.adsbygoogle = window.adsbygoogle || []).push({})
    // },
    // cleanupAds(adSlot) {
    //   document.querySelectorAll('.adsbygoogle').forEach(adElement => {
    //     if (adElement.getAttribute('data-ad-slot') === adSlot) {
    //       adElement.innerHTML = ''
    //       adElement.removeAttribute('data-adsbygoogle-status')
    //     }
    //   })
    // }
  }
}
</script>

<style scoped>
</style>
