import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { setMissedCall } from '@/utilities/missedCall'
import { setVoicemailStorage } from '../utilities/voicemailHelper'
import adminRouter from './admin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/register',
    name: 'Registration',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Registration.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/auth/:social?',
    name: 'Auth',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/forgot-password/:token?',
    name: 'ForgotPassword',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ResetPassword.vue')
  },
  {
    path: '/user/confirm_email/:token?',
    name: 'ConfirmEmail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ConfirmEmail.vue')
  },
  {
    path: '/select-number',
    name: 'SelectNumber',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/SelectNumber.vue')
  },
  {
    path: '/complete-reset-password',
    name: 'CompleteResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/CompleteResetPassword.vue'
      )
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/purchase',
    name: 'Purchase',
    component: () =>
      import(/* webpackChunkName: "VideoCall" */ '../views/Purchase')
  },
  {
    path: '/',
    redirect: { name: 'Calls' },
    name: 'Base',
    component: () => import(/* webpackChunkName: "NoUser" */ '../views/Base'),
    // meta: { requiresAuth: true },
    children: [
      {
        path: '/calls',
        name: 'Calls',
        component: () =>
          import(/* webpackChunkName: "VideoCall" */ '../views/Calls')
      },
      // {
      //   path: '/call/:phoneNumber',
      //   name: 'Call',
      //   component: () =>
      //     import(/* webpackChunkName: "VideoCall" */ '../views/Calls')
      // },
      {
        path: '/messages',
        name: 'Messages',
        component: () =>
          import(/* webpackChunkName: "VideoCall" */ '../views/Messages')
      },
      {
        path: '/voicemails',
        name: 'Voicemails',
        component: () =>
          import(/* webpackChunkName: "VideoCall" */ '../views/Voicemails')
      },
      {
        path: '/voicemail/:uuid',
        name: 'Voicemail',
        redirect: { name: 'Voicemails' }
      },
      {
        path: '/archive',
        name: 'Archive',
        component: () =>
          import(/* webpackChunkName: "VideoCall" */ '../views/Archive')
      },
      {
        path: '/spam',
        name: 'Spam',
        component: () =>
          import(/* webpackChunkName: "VideoCall" */ '../views/Spam')
      },
      {
        path: '/upgrade',
        name: 'Upgrade',
        component: () =>
          import(/* webpackChunkName: "VideoCall" */ '../views/Upgrade')
      },
      {
        path: '/labels',
        name: 'Labels',
        component: () =>
          import(/* webpackChunkName: "Labels" */ '../views/Labels')
      },
      {
        path: '/contacts',
        name: 'Contacts',
        component: () =>
          import(/* webpackChunkName: "NewMessage" */ '../views/Contacts')
      },
      {
        path: '/new-contact',
        name: 'NewContact',
        component: () =>
          import(/* webpackChunkName: "NewMessage" */ '../views/NewContact')
      },
      {
        path: '/edit-contact/:uuid',
        name: 'EditContact',
        component: () =>
          import(/* webpackChunkName: "NewMessage" */ '../views/NewContact')
      },
      ...adminRouter
    ]
  },
  {
    path: '/settings',
    name: 'Settings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Settings.vue')
  },
  {
    path: '/add-rule',
    name: 'AddRule',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AddRule.vue')
  },
  {
    path: '/edit-rule/:uuid',
    name: 'EditRule',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AddRule.vue')
  },
  {
    path: '/manage-rule',
    name: 'ManageRule',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ManageRule.vue')
  },
  {
    path: '/calling-rates',
    name: 'CallingRates',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/CallingRates.vue')
  },
  {
    path: '/billing-history',
    name: 'BillingHistory',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/BillingHistory.vue')
  },
  {
    path: '/new-message',
    name: 'NewMessage',
    component: () =>
      import(/* webpackChunkName: "NewMessage" */ '../views/NewMessage')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Calls') {
    const phoneNumber = to.query.phoneNumber
    phoneNumber && setMissedCall(phoneNumber)
  }
  if (to.name === 'Voicemails' && to.params.uuid) {
    setVoicemailStorage(to.params.uuid)
  }

  if (!to.params.redirect) store.commit('calls/setCallItem', null)
  store.commit('calls/setVoicemailItem', null)

  const userProfile = store.getters.userProfile
  if (
    to.name !== 'SelectNumber' && to.name !== 'Login' && to.name !== 'Registration' &&
    userProfile && !userProfile.number && !userProfile.is_admin
  ) {
    if (to.name === 'Calls') return store.dispatch('logout')
    if (to.name === 'ResetPassword') {
      store.dispatch('logout', false)
      next()
      return
    }

    return next({
      path: '/select-number'
    })
  }
  if (to.name === 'SelectNumber' && userProfile && userProfile.number) {
    return next({
      path: userProfile.is_admin ? '/admin' : '/calls'
    })
  }
  next()
})

export default router
