import axios from 'src/_services/axios-configs'

const state = {
  voicemailsGreeting: [],
  defaultGreeting: null
}

const getters = {
  voicemailsGreeting: state => state.voicemailsGreeting,
  defaultGreeting: state => state.defaultGreeting
}

const mutations = {
  SET_VOICEMAIL_GREETING(state, voicemailsGreeting) {
    state.voicemailsGreeting = voicemailsGreeting
    state.defaultGreeting = voicemailsGreeting.find(e => e.is_default)
  }
}

const actions = {
  async getVoicemailsGreeting({ commit }, payload) {
    try {
      const { data } = await axios.get('voicemail_greeting')

      commit('SET_VOICEMAIL_GREETING', data.data)
    } catch (error) {
      throw new Error(error)
    }
  },
  saveGreeting (_, data) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios
        .post('/voicemail_greeting', data, config)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  deleteGreeting (_, uuid) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/voicemail_greeting/${uuid}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  setDefaultGreeting (_, uuid) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/voicemail_greeting/set_as_default/${uuid}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getGreetingFile (_, name) {
    const type = name.split('.')[1]
    return new Promise((resolve, reject) => {
      axios
        .get(`/voicemail_greeting/file/${name}`, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': `audio/${type}`
          }
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          console.log(err)
          reject(err.response.data)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
