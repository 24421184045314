import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import './scss/app.scss'
import moment from 'moment'
import VS2 from 'vue-script2'

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    formatDate: function (val, format = 'YYYY-MM-DD HH:mm:ss') {
      return val ? moment(val).format(format) : ''
    },
    formatDateShort: function (val, format = 'ddd h:mm A') {
      return val ? moment(val).format(format) : ''
    },
    formatDateOnly: function (val, format = 'YYYY-MM-DD') {
      return val ? moment(val).format(format) : ''
    },
    onlyNumbers(event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
    },
    onlyLettersAndNumbers (event) {
      if ((!event.target.selectionStart && event.key === ' ') || !/^[A-Za-z \d]+$/.test(event.key)) return event.preventDefault()
    }
  }
})

Vue.use(VS2)

new Vue({
  router,
  axios,
  store,
  vuetify,
  data: {
    currentUser: null
  },
  render: (h) => h(App)
}).$mount('#app')
