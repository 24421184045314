import axios from 'src/_services/axios-configs'

const state = {
  contacts: [],
  total: 0,
  selectedContact: null,
  listLoading: false
}

const getters = {
  contacts: state => state.contacts,
  listLoading: state => state.listLoading,
  total: state => state.total,
  getFullNameByNumber: (state) => (number) => {
    if (!number) return ''
    const contact = state.contacts.find((contact) => contact.phone_number === number)
    if (contact) {
      const fullName = `${contact.first_name || ''} ${contact.last_name || ''}`.trim()
      if (fullName) {
        return fullName
      }
    }
    return number
  }
}

const mutations = {
  SET_CONTACTS(state, contacts) {
    state.contacts = contacts.map(item => {
      return {
        ...item,
        fullName: `${item.first_name || ''} ${item.last_name || ''}`.trim().toLowerCase()
      }
    })
  },
  SET_LIST_LOADING(state, loading) {
    state.listLoading = loading
  },
  SET_CONTACTS_TOTAL(state, total) {
    state.total = total
  }
}

const actions = {
  async createContact(_, payload) {
    try {
      const { data } = await axios.post('contacts', payload)

      return data.success
    } catch (error) {
      throw new Error(error)
    }
  },
  async updateContact(_, payload) {
    try {
      const { data } = await axios.patch(`contacts/${payload.uuid}`, { ...payload.payload })

      return data.success
    } catch (error) {
      throw new Error(error)
    }
  },
  async getContactsById({ commit }, id) {
    try {
      const { data } = await axios.get(`contacts?userLabelUuid=${id}`)
      return data
    } catch (error) {
      throw new Error(error)
    }
  },
  async getContactbyUuid(_, uuid) {
    return new Promise((resolve, reject) => {
      axios.get(`contacts/${uuid}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  async deleteContact(_, uuid) {
    return new Promise((resolve, reject) => {
      axios.delete(`contacts/${uuid}`)
        .then(({ data }) => {
          resolve(data.success)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  async getContacts({ commit }) {
    commit('SET_LIST_LOADING', true)
    try {
      const { data } = await axios.get('contacts')
      commit('SET_CONTACTS', data.data)
      commit('SET_CONTACTS_TOTAL', data.total)
    } catch (error) {
      throw new Error(error)
    } finally {
      commit('SET_LIST_LOADING', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
